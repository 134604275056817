"use client";

import { FeedContext } from "@finbackoffice/site-core";
import { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { sortById } from "@finbackoffice/fe-core";
import { HoursRange } from "@finbackoffice/websocket-client";
import Loading from "components/base/loading/Loading";
import SportGamesContent from "components/pages/home/sport-games/content/SportGamesContent";
import { FeedTypeEnum, useSocketStorage } from "hooks";
import { PrematchContext } from "contexts";

type IProps = {
    hoursRange: HoursRange;
    sliderSpeed: number;
};

const PrematchWidget: FC<IProps> = ({ sliderSpeed, hoursRange }) => {
    const [activeSportId, setActiveSportId] = useState<number | null>(null);
    const { getEventsBySportId } = useSocketStorage(FeedTypeEnum.prematch);
    const { prematchFeed, prematchIsLoading, hasPrematchSports } = useContext(FeedContext);
    const { setHoursRange, hoursRange: hoursRangeFromContext } = useContext(PrematchContext);
    const validHoursRange = hoursRange === hoursRangeFromContext;

    useEffect(() => {
        if (!prematchIsLoading && hasPrematchSports && validHoursRange) {
            const selectedSportDoesNotExist =
                activeSportId &&
                prematchFeed.findIndex((sport) => sport.id === activeSportId) === -1;

            if (prematchFeed.length && (!activeSportId || selectedSportDoesNotExist)) {
                setActiveSportId(prematchFeed.sort(sortById)[0].id);
            }
        } else {
            setActiveSportId(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prematchFeed]);

    useEffect(() => {
        if (!validHoursRange) {
            setHoursRange(hoursRange);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [validHoursRange]);

    useEffect(() => {
        return () => {
            if (hoursRange !== HoursRange.ALL) {
                setHoursRange(HoursRange.ALL);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSportClickHandler = useCallback((sportId: number) => {
        setActiveSportId(sportId);
    }, []);

    const activeSportEvents = useMemo(
        () => (activeSportId ? getEventsBySportId(activeSportId) : []),
        [activeSportId, getEventsBySportId],
    );

    if (prematchIsLoading) return <Loading />;

    return (
        <SportGamesContent
            events={activeSportEvents}
            sports={prematchFeed}
            activeSportId={activeSportId}
            onSportClickHandler={onSportClickHandler}
            sliderSpeed={sliderSpeed}
        />
    );
};

export default PrematchWidget;
