"use client";

import { FC, useContext, useEffect } from "react";
import { CasinoFavoritesContext } from "@finbackoffice/site-core";
import { ICasinoFavoriteResponse } from "@finbackoffice/clientbff-client";
import { IReelsDataState } from "../../types/reels.data";
import ErrorBoundary from "components/base/error-boundary/ErrorBoundary";
import Home from "components/pages/home/Home";

type Props = {
    reels: IReelsDataState | null;
    favoriteGames: ICasinoFavoriteResponse[] | null;
};

export const Homepage: FC<Props> = ({ reels, favoriteGames }) => {
    const { updateFavoriteGames } = useContext(CasinoFavoritesContext);

    useEffect(() => {
        if (!favoriteGames) {
            return;
        }

        updateFavoriteGames(favoriteGames);
    }, [favoriteGames, updateFavoriteGames]);

    return (
        <ErrorBoundary name={Home.name}>
            <Home reels={reels} />
        </ErrorBoundary>
    );
};
