"use client";

import classNames from "classnames";
import {
    ReactNode,
    FC,
    PropsWithChildren,
    forwardRef,
    RefAttributes,
    useMemo,
    Dispatch,
    SetStateAction,
} from "react";
import { CasinoGameTag, TranslationScopes } from "@finbackoffice/enums";
import { usePathnameWithLocal, useRuntimeConfig, useTranslation } from "@finbackoffice/site-core";
import Link from "next/link";
import Loading from "components/base/loading/Loading";
import Translate from "components/base/translate/Translate";
import Button from "components/base/button/Button";
import { Chars } from "utils/strings";
import styles from "./casino-widget-container.module.sass";

type CasinoWidgetContainerDefaultHeaderProps = {
    href?: string;
    totalCount: number;
    expanded: boolean;
    setExpanded: Dispatch<SetStateAction<boolean>>;
    id: string;
    label?: string | { title: string; sub_title: string };
    tag?: CasinoGameTag;
};

type CasinoWidgetContainerVariant =
    | "mozaicRain"
    | "mozaicMix"
    | "mozaicDefault"
    | "allGamesContainer"
    | "narrowVertical"
    | "wideRow"
    | "narrowRow"
    | "row"
    | "shop"
    | "reels"
    | "topWinners";

type Props = {
    renderHeader?: ReactNode;
    defaultHeaderProps?: CasinoWidgetContainerDefaultHeaderProps;
    variant?: CasinoWidgetContainerVariant;
    expand?: boolean;
    height?: number;
    loading?: boolean;
    wrapperClassName?: string;
};

const CasinoWidgetContainer: FC<Props & PropsWithChildren & RefAttributes<HTMLDivElement>> =
    forwardRef(
        (
            {
                renderHeader,
                children,
                variant = "row",
                expand,
                height,
                loading,
                defaultHeaderProps,
                wrapperClassName,
            },
            ref,
        ) => {
            const { pathnameValues } = usePathnameWithLocal();
            const { t: tWidgets } = useTranslation(TranslationScopes.CasinoWidgets);
            const { t } = useTranslation(TranslationScopes.Casino);
            const COMMON_SITE_CONFIGS = useRuntimeConfig("COMMON_SITE_CONFIGS");
            const { href, totalCount, expanded, setExpanded, id, label, tag } =
                defaultHeaderProps || {};
            const isHome = pathnameValues.length === 0;

            const titleKey = `casino_${id}_title`;
            const subTitleKey = `casino_${id}_sub_title`;
            const titleTranslationById = tWidgets(titleKey);
            const subTitleTranslationById = tWidgets(subTitleKey);
            const isSubTitleFallback = subTitleTranslationById === subTitleKey;
            const isTitleFallback = titleTranslationById === titleKey;

            const renderHeaderButton = useMemo(
                () =>
                    href ? (
                        <Link href={href} className={styles.seeAll}>
                            <Translate tid="casino_see_all" namespace={TranslationScopes.Casino} />{" "}
                            {Chars.nbsp}
                            {`(${totalCount})`}
                        </Link>
                    ) : (
                        <>
                            {expanded && (
                                <Button
                                    className={styles.close}
                                    type="button"
                                    onClick={() => setExpanded?.(false)}
                                />
                            )}
                            {!!totalCount && totalCount > 0 && !expanded && (
                                <Button
                                    type="button"
                                    variant="custom"
                                    className={styles.seeAll}
                                    onClick={() => setExpanded?.(true)}>
                                    <Translate
                                        tid="casino_see_all"
                                        namespace={TranslationScopes.Casino}
                                    />{" "}
                                    {Chars.nbsp}
                                    {`(${totalCount})`}
                                </Button>
                            )}
                        </>
                    ),
                [href, totalCount, expanded, setExpanded],
            );

            const renderDefaultHeader = useMemo(() => {
                const showTitle = (!isTitleFallback && id) || (isTitleFallback && label);
                const showSubTitle = (!isSubTitleFallback && id) || (isSubTitleFallback && label);
                return (
                    <>
                        <span>
                            {showTitle &&
                                (id && !isTitleFallback
                                    ? !!titleTranslationById.trim() && (
                                          <span>
                                              <Translate
                                                  tid={`casino_${id}_title`}
                                                  namespace={TranslationScopes.CasinoWidgets}
                                              />
                                          </span>
                                      )
                                    : label &&
                                      typeof label !== "string" && (
                                          <span>
                                              <Translate
                                                  tid={`${label?.title}`}
                                                  namespace={
                                                      isHome
                                                          ? TranslationScopes.Home
                                                          : TranslationScopes.Casino
                                                  }
                                              />
                                          </span>
                                      ))}
                            {showSubTitle && (
                                <>
                                    {id && !isSubTitleFallback
                                        ? !!subTitleTranslationById.trim() && (
                                              <strong>
                                                  <Translate
                                                      tid={`casino_${id}_sub_title`}
                                                      namespace={TranslationScopes.CasinoWidgets}
                                                  />
                                              </strong>
                                          )
                                        : label &&
                                          (typeof label !== "string" ? (
                                              <strong>
                                                  <Translate
                                                      tid={`${label?.sub_title}`}
                                                      namespace={
                                                          isHome
                                                              ? TranslationScopes.Home
                                                              : TranslationScopes.Casino
                                                      }
                                                      replace={{
                                                          app_name: COMMON_SITE_CONFIGS.appName,
                                                          tag: tag
                                                              ? t(`casino_${tag?.toLowerCase()}`)
                                                              : "",
                                                      }}
                                                  />
                                              </strong>
                                          ) : (
                                              typeof label === "string" && (
                                                  <strong>
                                                      <Translate
                                                          tid={`${label}`}
                                                          namespace={TranslationScopes.Casino}
                                                      />
                                                  </strong>
                                              )
                                          ))}
                                </>
                            )}
                        </span>
                        {id !== "all" && !!totalCount && renderHeaderButton}
                    </>
                );
            }, [
                COMMON_SITE_CONFIGS.appName,
                id,
                isHome,
                isSubTitleFallback,
                isTitleFallback,
                label,
                renderHeaderButton,
                t,
                tag,
                subTitleTranslationById,
                titleTranslationById,
                totalCount,
            ]);

            return (
                <div
                    id={variant}
                    style={height ? { minHeight: height } : undefined}
                    className={classNames(
                        styles.casinoCompactLayoutItem,
                        variant && styles[variant],
                        expand && styles.expand,
                        wrapperClassName && styles[wrapperClassName],
                    )}>
                    <div>
                        <div
                            className={styles.expandedContent}
                            ref={ref}
                            style={height ? { height } : undefined}>
                            <div className={styles.casinoCompactLayoutItemHeader}>
                                {renderHeader || renderDefaultHeader}
                            </div>
                            <div className={styles.casinoCompactLayoutItemContent}>
                                {children}
                                {loading && <Loading />}
                            </div>
                        </div>
                    </div>
                </div>
            );
        },
    );

export default CasinoWidgetContainer;
