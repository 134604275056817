"use client";

import { FC, createContext, useContext, useMemo } from "react";
import { useLazyLoad } from "@finbackoffice/site-core";
import { CasinoGameTag } from "@finbackoffice/enums";
import { CasinoGamesContext, ICasinoGamesContext } from "../../../games/CasinoGames";
import Compact from "./layouts/compact/Compact";

export enum CasinoGamesRowVariantEnum {
    Horizontal = "horizontal",
    Vertical = "vertical",
    NarrowVertical = "narrow_vertical",
    RoundedHorizontal = "rounded_horizontal",
    SquaredHorizontal = "squared_horizontal",
    Wide = "wide",
    Narrow = "narrow",
}

type ICasinoGamesRowContext = ICasinoGamesContext & {
    isSlots: boolean;
    endRef: (node: HTMLDivElement) => void;
    backImg?: boolean;
};

export const CasinoGamesRowContext = createContext<ICasinoGamesRowContext>(null as any);

type IProps = {
    variant: CasinoGamesRowVariantEnum;
    tag?: CasinoGameTag;
    backImg?: boolean;
};

const CasinoGamesRow: FC<IProps> = ({ variant, tag, backImg }) => {
    const {
        id,
        casinoGamesState,
        label,
        hideTag,
        hasMore,
        setPagination,
        notification,
        isSlots,
        href,
        options,
        wrapperClassName,
        imgSrc,
        imgFolder,
    } = useContext(CasinoGamesContext);
    const { endRef } = useLazyLoad(casinoGamesState.loading, setPagination, hasMore);

    const value = useMemo(
        () => ({
            id,
            isSlots,
            casinoGamesState,
            hasMore,
            endRef,
            notification,
            label,
            hideTag,
            variant,
            setPagination,
            href,
            options,
            wrapperClassName,
            tag,
            backImg,
            imgSrc,
            imgFolder,
        }),
        [
            id,
            isSlots,
            casinoGamesState,
            hasMore,
            endRef,
            notification,
            label,
            hideTag,
            variant,
            setPagination,
            href,
            options,
            wrapperClassName,
            tag,
            backImg,
            imgSrc,
            imgFolder,
        ],
    );

    return (
        <CasinoGamesRowContext.Provider value={value}>
            <Compact />
        </CasinoGamesRowContext.Provider>
    );
};

export default CasinoGamesRow;
