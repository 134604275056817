"use client";

import {
    FC,
    RefObject,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { ICasinoGame } from "@finbackoffice/clientbff-client";
import { CasinoWidgetFolderEnum } from "@finbackoffice/fe-core";
import { formatCasinoProviderName, useSearchParamsQuery } from "@finbackoffice/site-core";
import { usePathname } from "next/navigation";
import Translate from "components/base/translate/Translate";
import { CasinoGameImgFolderType } from "utils/helpers";
import SiteNotification from "components/base/notification/SiteNotification";
import CasinoWidgetContainer from "components/pages/casino/base/casino-widget-container/CasinoWidgetContainer";
import { InitialDataContext } from "contexts";
import { CasinoGamesRowContext, CasinoGamesRowVariantEnum } from "../../CasinoGamesRow";
import CasinoGameItem from "../../../../../game-item/CasinoGameItem";
import styles from "./compact.module.sass";

const Compact: FC = () => {
    const {
        casinoGamesState,
        hasMore,
        endRef,
        notification,
        isSlots,
        label,
        hideTag,
        variant,
        id,
        href,
        options,
        wrapperClassName,
        tag,
        backImg,
        imgSrc,
        imgFolder,
    } = useContext(CasinoGamesRowContext);
    const { siteHeaderHeight } = useContext(InitialDataContext);
    const pathname = usePathname();
    const { query } = useSearchParamsQuery();
    const [expanded, setExpanded] = useState(false);
    const containerRef: RefObject<HTMLDivElement> = useRef(null);
    const isReadyTrigeredOnce = useRef(false);
    const [containerHeight, setContainerHeight] = useState(0);
    const isAllSection =
        variant &&
        [CasinoGamesRowVariantEnum.Vertical, CasinoGamesRowVariantEnum.NarrowVertical].includes(
            variant,
        );

    useEffect(() => {
        if (query?.section) {
            const scrollPosition = (containerRef.current?.offsetTop ?? 0) - siteHeaderHeight;

            if (window.scrollY !== scrollPosition) {
                window.scrollTo({
                    top: scrollPosition,
                    behavior: "smooth",
                });
            }
        }
    }, [pathname, variant, query?.section, containerRef, siteHeaderHeight]);

    const gameItemLayout = useMemo(() => {
        switch (variant) {
            case CasinoGamesRowVariantEnum.RoundedHorizontal:
                return "rounded";
            case CasinoGamesRowVariantEnum.SquaredHorizontal:
                return "squared";
            default:
                return "default";
        }
    }, [variant]);

    const widgetContainerVariant = useMemo(() => {
        switch (variant) {
            case CasinoGamesRowVariantEnum.Vertical:
                return "allGamesContainer";
            case CasinoGamesRowVariantEnum.NarrowVertical:
                return "narrowVertical";
            case CasinoGamesRowVariantEnum.Wide:
                return "wideRow";
            case CasinoGamesRowVariantEnum.Narrow:
                return "narrowRow";
            default:
                return undefined;
        }
    }, [variant]);

    const handleOnReady = useCallback(() => {
        if (!isAllSection && !isReadyTrigeredOnce.current) {
            isReadyTrigeredOnce.current = true;
            if (!containerHeight && containerRef.current?.clientHeight) {
                setContainerHeight(containerRef.current?.clientHeight);
            }
        }
    }, [containerHeight, isAllSection]);

    const getFallbackSrc = useCallback(
        (game: ICasinoGame) => {
            if (variant) {
                if (variant === CasinoGamesRowVariantEnum.Wide) {
                    return game.image_filled;
                } else if (
                    [
                        CasinoGamesRowVariantEnum.NarrowVertical,
                        CasinoGamesRowVariantEnum.Narrow,
                    ].includes(variant)
                ) {
                    return game.image_portrait;
                }
            }

            return undefined;
        },
        [variant],
    );

    const getImgSrcFolder = useCallback(
        (game: ICasinoGame): CasinoGameImgFolderType | undefined => {
            if (
                variant === CasinoGamesRowVariantEnum.Narrow ||
                variant === CasinoGamesRowVariantEnum.NarrowVertical
            ) {
                return "narrow";
            } else if (
                options?.megaways &&
                variant === CasinoGamesRowVariantEnum.Wide &&
                !expanded
            ) {
                return "megaways";
            } else if (imgFolder && imgFolder === CasinoWidgetFolderEnum.Central) {
                return `${imgFolder}/${formatCasinoProviderName(game.category)}`;
            }

            return undefined;
        },
        [expanded, imgFolder, options?.megaways, variant],
    );

    const renderGame = useCallback(
        (game: ICasinoGame) => (
            <CasinoGameItem
                key={game.id}
                game={game}
                hideTag={hideTag}
                onReady={handleOnReady}
                hidePlayButtons={!isSlots}
                layout={gameItemLayout}
                fallbackSrc={getFallbackSrc(game)}
                backImg={backImg}
                imgSrc={imgSrc}
                imgFolder={getImgSrcFolder(game)}
            />
        ),
        [
            backImg,
            gameItemLayout,
            getFallbackSrc,
            getImgSrcFolder,
            handleOnReady,
            hideTag,
            imgSrc,
            isSlots,
        ],
    );

    const containerVariant = useMemo(() => {
        if (variant !== CasinoGamesRowVariantEnum.Narrow && expanded) {
            return undefined;
        }
        return widgetContainerVariant;
    }, [expanded, variant, widgetContainerVariant]);

    return (
        <CasinoWidgetContainer
            ref={containerRef}
            expand={expanded}
            height={containerHeight}
            loading={casinoGamesState.loading}
            wrapperClassName={wrapperClassName}
            variant={containerVariant}
            defaultHeaderProps={{
                href,
                totalCount: casinoGamesState.totalCount,
                expanded,
                setExpanded,
                id,
                label,
                tag,
            }}>
            {!casinoGamesState.loading && !casinoGamesState.games.length && (
                <div className="no-game">
                    <Translate tid="no_games" />
                </div>
            )}
            {Boolean(casinoGamesState.games.length) && (
                <>
                    {casinoGamesState.games.map(renderGame)}
                    {(expanded || (!expanded && isAllSection)) && hasMore && <div ref={endRef} />}
                </>
            )}
            {notification.type && (
                <SiteNotification
                    type={notification.type}
                    title={notification.error}
                    message={notification.message}
                    wrapperClassname={styles.notification}
                />
            )}
        </CasinoWidgetContainer>
    );
};

export default Compact;
