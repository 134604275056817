"use client";

import { FC, ReactNode, useMemo } from "react";
import Link from "next/link";
import dynamic from "next/dynamic";
import { HomePageSectionsEnum } from "@finbackoffice/fe-core";
import { TranslationScopes } from "@finbackoffice/enums";
import { HoursRange } from "@finbackoffice/websocket-client";
import PrematchWidget from "components/pages/sport/prematch/PrematchWidget";
import Translate from "components/base/translate/Translate";
import styles from "./sport-games.module.sass";

const PrematchTopWidget = dynamic(
    () => import("components/pages/sport/prematch/top/widget/PrematchTopWidget"),
    {
        // loading: () => <PreloadComponent style={{ height: 190, marginBottom: 8 }} />,
        ssr: false,
    },
);

const PrematchUpcomingWidget = dynamic(
    () => import("components/pages/sport/prematch/upcoming/widget/PrematchUpcomingWidget"),
    {
        // loading: () => <PreloadComponent style={{ height: 190, marginBottom: 8 }} />,
        ssr: false,
    },
);

type IProps = {
    type:
        | HomePageSectionsEnum.PrematchTop
        | HomePageSectionsEnum.PrematchUpcoming
        | HomePageSectionsEnum.LiveUpcoming;
    icon: ReactNode;
    title: string;
    href: string;
    hoursRange: HoursRange;
    sliderSpeed: number;
    onSeeAllClick?: () => void;
};

const SportGames: FC<IProps> = ({
    icon,
    title,
    href,
    type,
    hoursRange,
    sliderSpeed,
    onSeeAllClick,
}) => {
    const renderContent = useMemo(() => {
        switch (type) {
            case HomePageSectionsEnum.PrematchTop:
                return <PrematchTopWidget hoursRange={hoursRange} sliderSpeed={sliderSpeed} />;
            case HomePageSectionsEnum.LiveUpcoming:
                return <PrematchUpcomingWidget hoursRange={hoursRange} sliderSpeed={sliderSpeed} />;
            case HomePageSectionsEnum.PrematchUpcoming:
                return <PrematchWidget hoursRange={hoursRange} sliderSpeed={sliderSpeed} />;
            default:
                return null;
        }
    }, [type, hoursRange, sliderSpeed]);

    return (
        <div className={styles.sportGames}>
            <div className={styles.header}>
                <span>
                    {icon}
                    {title}
                </span>
                <Link href={href} onClick={onSeeAllClick}>
                    <Translate tid="casino_see_all" namespace={TranslationScopes.Casino} />
                </Link>
            </div>
            {renderContent}
        </div>
    );
};

export default SportGames;
